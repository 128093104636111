import axios from 'axios'


SERVICE_URL = "/__internal/swear_words_checker"

class SwearWordsChecker
  check: (text, projectId) ->
    promiseHandler = (resolve, reject) =>
      axiosHandlerResolv = (data) =>
        console.log(data)
        resolve(new Result(data.data))
      axios.post(@checkUrl(), @serviceParams(text, projectId)).then(axiosHandlerResolv, reject)
    new Promise(promiseHandler)

  serviceUrl: ->
    SERVICE_URL

  checkUrl: ->
    "#{@serviceUrl()}/check"

  serviceParams: (text, projectId) ->
    p = {text: text}
    p[@getCsrfMeta('csrf-param')]=@getCsrfMeta('csrf-token')
    p['project_id'] = projectId if projectId
    p

  getCsrfMeta: (name) ->
    el = document.querySelector("meta[name='#{name}']")
    if el?.content?
      el.content
    else
      null


class Result
  constructor: (serviceResult) ->
    @serviceResult = serviceResult

  isContains: ->
    @serviceResult.is_contains

instance = new SwearWordsChecker()

window.Agiki ||= {}
window.Agiki.Common ||= {}
unless window.Agiki.Common.SwearWordsChecker
  window.Agiki.Common.SwearWordsChecker = instance

export default SwearWordsChecker
