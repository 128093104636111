class SiteHeaderResizer
  isEnabled: ->
    el = document.querySelector("meta[name='agiki-js-header-adaptive']")
    if el
      el.content == 'true'
    else
      false

  resetInstanceVariable: ->
    @__headerContainerElement = null
    @__headerTitleELement = null
    @__headerLogoElement = null
    @__headerMenuLinksContainerElement = null
    @__headerMenuLinkElements = null
    @__headerMenuRightPart = null

    @modifiers().forEach (el) ->
      el.resetInstanceVariable()

    true

  headerContainerElement: ->
    @__headerContainerElement ||= document.querySelector(".header__container")

  headerTitleElement: ->
    @__headerTitleELement ||= @headerContainerElement().querySelector('.header__title')

  headerLogoElement: ->
    @__headerLogoElement ||= @headerContainerElement().querySelector('.header__logo')

  headerMenuLinksContainerElement: ->
    @__headerMenuLinksContainerElement ||= @headerContainerElement().querySelector('.header__menu')

  headerMenuLinkElements: ->
    @__headerMenuLinkElements ||= @headerMenuLinksContainerElement().querySelectorAll('.header__menu-item')

  headerMenuRightPart: ->
    @__headerMenuRightPart ||= @headerContainerElement().querySelector('.header__right-part')

  headerLeftPartElement: ->
    if @headerTitleElement()
      return @headerTitleElement()
    else
      return @headerLogoElement()

  headerCenterPartElement: ->
    @headerMenuLinksContainerElement()

  headerRigthPartElement: ->
    @headerMenuRightPart()

  headerLeftPartWidth: ->
    v= @elementWidth(@headerLeftPartElement())
    computedStyle = getComputedStyle(@headerLeftPartElement());
    v += parseFloat(computedStyle.marginLeft)
    v += parseFloat(computedStyle.marginRight)
    v

  headerCenterPartWidth: ->
    v = @elementWidth(@headerCenterPartElement())
    computedStyle = getComputedStyle(@headerCenterPartElement());
    v += parseFloat(computedStyle.marginLeft)
    v += parseFloat(computedStyle.marginRight)
    v

  headerRigthPartWidth: ->
    v = @elementWidth(@headerRigthPartElement())
    computedStyle = getComputedStyle(@headerRigthPartElement());
    v += parseFloat(computedStyle.marginLeft)
    v += parseFloat(computedStyle.marginRight)
    v

  headerMenuItemPadding: ->
    el = @headerMenuLinkElements()[0]
    computedStyle = getComputedStyle(el);
    parseFloat(computedStyle.paddingLeft)

  isHeaderMenuLinksContainerElementOver: ->
    containerContentWidth = @elementContentWidth(@headerContainerElement())

    leftPartWidth = @headerLeftPartWidth()
    centerPartWidth = @headerCenterPartWidth()
    rigthPartWidth = @headerRigthPartWidth()

    containerContentWidth < (leftPartWidth + centerPartWidth + rigthPartWidth)

  isHidden: (el) ->
    style = window.getComputedStyle(el);
    style.display == 'none'

  elementContentWidth: (el) ->
    computedStyle = getComputedStyle(el);
    elWidth = el.clientWidth;
    elWidth -= parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight);
    elWidth

  reset: ->
    @modifiers().forEach (el) ->
      el.reset()

    @resetInstanceVariable()
    true

  modifiers: ->
    @__modifiers ||= [
      new ModifierMenuItemDisableWordWrap(@)
      new ModifierMenuItemPadding(@)
      new ModifierHeaderMenuMarginLeft(@)
      new ModiferMobileView(@)
    ]

  elementWidth: (el) ->
    el.clientWidth

  loadedHandler: (event) ->
    @resetInstanceVariable()
    window.removeEventListener('resize', @resizeWindowHandler.bind(@))
    window.addEventListener('resize', @resizeWindowHandler.bind(@))
    handler = =>
      @resizeWindowHandler(event)
    setTimeout handler, 10

  resizeWindowHandler: (event) ->
    @modifiers().forEach (mod) =>
      mod.reset()

    @modifiers().some (mod) =>
      mod.modify()
      !@isHeaderMenuLinksContainerElementOver()

    @resetHeaderContainerVisibility()

  resetHeaderContainerVisibility: ->
    if @headerContainerElement()
      @headerContainerElement().style.visibility = null


  register: ->
    return unless @isEnabled()
    document.addEventListener("turbolinks:load", @loadedHandler.bind(@))

class ModifierBase
  constructor: (scope) ->
    @scope = scope

  call: ->
    @reset()
    @modify()

  modify: ->
    console.log("implement")

  modifyByCookie: ->
    console.log("implement")

  reset: ->
    console.log('implement')

  resetInstanceVariable: ->
    true

class ModifierMenuItemDisableWordWrap extends ModifierBase
  modify: ->
    return unless @scope.isHeaderMenuLinksContainerElementOver()

    @__modify()

    true

  __modify: ->
    @scope.headerMenuLinkElements().forEach (element) ->
      element.style.flexShrink = 0

  reset: ->
    @scope.headerMenuLinkElements().forEach (element) ->
      element.style.flexShrink = null

class ModifierMenuItemPadding extends ModifierBase
  MIN_PADDING_FOR_HEADER_MENU_ITEM = 5
  STEP_PADDING_CHANGE_FOR_HEADER_MENU_ITEM = 1

  DEFAULT_PADDING = 20

  modify: ->
    padding = DEFAULT_PADDING

    while(padding > MIN_PADDING_FOR_HEADER_MENU_ITEM && @scope.isHeaderMenuLinksContainerElementOver())
      newPadding = padding - STEP_PADDING_CHANGE_FOR_HEADER_MENU_ITEM
      @scope.headerMenuLinkElements().forEach (element) ->
        element.style.paddingLeft = "#{parseInt(newPadding)}px"
        element.style.paddingRight = "#{parseInt(newPadding)}px"
      padding = newPadding

    true

  reset: ->
    @scope.headerMenuLinkElements().forEach (element) ->
      element.style.paddingLeft = null
      element.style.paddingRight = null

class ModifierHeaderMenuMarginLeft extends ModifierBase
  MIN_MARGINE_FOR_HEADER_MENU = 10
  STEP_PADDING_CHANGE_FOR_HEADER_MENU_ITEM = 1

  MARGINE_LEFT_DEFAULT = 40

  modify: ->
    margineLeft = @headerMenuMargineLeft()

    isChange = false

    while(margineLeft > MIN_MARGINE_FOR_HEADER_MENU && @scope.isHeaderMenuLinksContainerElementOver())
      newMargine = margineLeft - STEP_PADDING_CHANGE_FOR_HEADER_MENU_ITEM
      @scope.headerMenuLinksContainerElement().style.margineLeft = "#{parseInt(newMargine)}px"
      margineLeft = newMargine
      isChange = true

  reset: ->
    @scope.headerMenuLinksContainerElement().style.margineLeft = "#{parseInt(MARGINE_LEFT_DEFAULT)}px"

  headerMenuMargineLeft: ->
    el = @scope.headerMenuLinksContainerElement()
    computedStyle = getComputedStyle(el);
    parseFloat(computedStyle.margineLeft)

class ModiferMobileView extends ModifierBase

  modify: ->
    return unless @scope.isHeaderMenuLinksContainerElementOver()

    @scope.headerMenuLinksContainerElement().style.display = 'none'

    @headerHamburger().style.display = "inherit"
    @headerHamburger().style.left = "#{@getLeftWindowOffset()}px"

    @scope.headerLeftPartElement().style.paddingLeft = "#{@calcOffsetLeftForLeftPart()}px"

    true

  reset: ->
    @scope.headerMenuLinksContainerElement().style.display = null

    @headerHamburger().style.display = null
    @headerHamburger().style.left = null

    @scope.headerLeftPartElement().style.paddingLeft = null

  resetInstanceVariable: ->
    @__mobileLogin = null
    @__desktopLogin = null
    @__headerHamburger = null
    @__leftPart = null

  mobileLogin: ->
    @__mobileLogin ||= @scope.headerMenuRightPart().querySelector(".header__mobile-login")

  desktopLogin: ->
    @__desktopLogin ||= @scope.headerMenuRightPart().querySelector(".header__auth-buttons")

  headerHamburger: ->
    @__headerHamburger ||= @scope.headerContainerElement().querySelector('.header__hamburger')

  leftPart: ->
    @__leftPart ||= @scope.headerContainerElement().querySelector(".header__left-part")

  getLeftWindowOffset: ->
    @scope.headerContainerElement().getBoundingClientRect().x + @headerContainerElementLeftPadding()

  headerContainerElementLeftPadding: ->
    computedStyle = getComputedStyle(@scope.headerContainerElement());
    parseFloat(computedStyle.paddingLeft)

  calcOffsetLeftForLeftPart: ->
    width = @headerContainerClearWidth()
    leftPartWidth = @headerLeftPartElementClearWidth()

    (width - leftPartWidth) / 2

  headerContainerClearWidth: ->
    computedStyle = getComputedStyle(@scope.headerContainerElement())
    v = @scope.headerContainerElement().clientWidth
    v -= parseFloat(computedStyle.paddingLeft)
    v -= parseFloat(computedStyle.paddingRight)
    v

  headerLeftPartElementClearWidth: ->
    computedStyle = getComputedStyle(@scope.headerLeftPartElement())
    v = @scope.headerLeftPartElement().clientWidth
    v -= parseFloat(computedStyle.paddingLeft)
    v -= parseFloat(computedStyle.paddingRight)
    v

instance = new SiteHeaderResizer()
instance.register()

export default instance
