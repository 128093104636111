import dasherize from "underscore.string/dasherize"
import camelize from "underscore.string/camelize"

class VueDynamicFields
  constructor: (Vue, options) ->
    @__Vue = Vue
    @__options = options
    @__staticComponents = []

  registerStaticComponent: (staticNamespace, name, component) ->
    @__staticComponents.push({
      staticNamespace: staticNamespace
      name: name
      component: component
    })

    @__Vue.component(@staticComponentId(staticNamespace, name), component)
    true

  staticComponentId: (staticNamespace, name) ->
    components = [staticNamespace, name, "#{camelize(name)}"].filter (s) ->
      s? && s.trim() != ''

    dasherize(components.join('-')).replace(/^-/, '')

  @buildInstance: (Vue, options) ->
    @__instance = new VueDynamicFields(Vue, options)

  @instance: ->
    @__instance

class VueDynamicFieldsPlugin

  @install: (Vue, options) ->
    @__registerGlobal(Vue, options)
    @__registerInstance(Vue, options)
    VueDynamicFields.buildInstance(Vue, options)

  @__registerGlobal: (Vue, options) ->
    Vue.$dynamicFields = @__dynamicFieldsHandler

  @__registerInstance: (Vue, options) ->
    Vue.prototype.$dynamicFields = @__dynamicFieldsHandler

  @__dynamicFieldsHandler: ->
    VueDynamicFields.instance()


export default VueDynamicFieldsPlugin
