import ahoy from "ahoy.js"

metaTrack = document.querySelector('meta[name=agiki-track]')
trackAllowed = (metaTrack? && metaTrack.getAttribute('content') == 'true')

ahoy.configure({
  startOnReady: trackAllowed
  trackVisits: trackAllowed
  cookies: trackAllowed
})

extendMethod = (old, wrapAdditionalProperties = false) ->
  if wrapAdditionalProperties
    extended = (additionalProperties = {}) ->
      metaTrackingObjects = document.querySelector('meta[name=agiki-tracking-objects]')
      if metaTrackingObjects
        additionalProperties['trackingObjects'] = metaTrackingObjects.getAttribute('content')
      old(additionalProperties)
  else
    extended = ()->
      old()
  return extended

ahoy.trackView = extendMethod(ahoy.trackView, true)
ahoy.trackClicks = extendMethod(ahoy.trackClicks)
ahoy.trackSubmits = extendMethod(ahoy.trackSubmits)
ahoy.trackChanges = extendMethod(ahoy.trackChanges)

$(document).on 'turbolinks:load', ->
  ahoy.trackView()

window.ahoy = ahoy
