//При подключение в родительском компоненте надо создать свойства:
// itemsCounter = (элементы списка к которому подключена пагинация).length
// currentPage: 1
// Также подключить обработку события "set-page", которое устанавливает свойство 'currentPage'

module.exports = {
    template: '#pagination-template',
    props: {
        itemsCounter: Number,
        perPage: {
            type: Number,
            default: 10
        },
        currentPage: {
            type: Number,
            default: 1,
        },
    },
    computed: {
        totalPages: function() {
            return this.itemsCounter ? Math.ceil(this.itemsCounter / this.perPage) : 1;
        },
        pageList: function () {
            var page = this.currentPage;
            var pages = this.totalPages;
            var list = [];
            for(var i = 1; i < pages + 1 ; i++){
                //Добавляем в массив 3 первые, 3 последние страницы и текущую страницу с соседями.
                //+ 2 страницы которые нужны для формирования кнопок с многотьями.
                if(i <= 3 || i >= page - 2 && i <= page + 1 || i >= pages - 3){
                	list.push(i);
                }
            }
            if(list.length > 6){
                if(page < 3 || page > pages - 5){
                    list[3] = '...';
                } else if(page > 2 && page < 6){
                    list[page + 1] = '...';
                } else if(page >= 6 && page < pages - 4){
                    list[3] = '...';
                    list[list.length - 4] = '...';
                }
            }
           return list;
        },
    },
    methods: {
        clickPage: function (page) {
            if(page == '...'){
                return;
            }
            bus.$emit('set-page', page);
            // Если у одного из родителей нет аттрибута [data-scroll="pagination-scroll-top"], то список скроллится к ближайшему родителю.
            var $dataScrollElement = $(this.$el).closest('[data-scroll="pagination-scroll-top"]'),
                $topScrollElement = $dataScrollElement.length ? $dataScrollElement : $(this.$el).parent();
            $('html, body').animate({ scrollTop: $topScrollElement.offset().top - 30 }, 500);
        }
    }
}
