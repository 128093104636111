import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

store = new Vuex.Store({
  state: {},
  mutations: {}
})

class Store
  __get: ->
    store

  registerModule: (path, module, options) ->
    _module = module
    if typeof(module) == 'string'
      _module = @findSupportedModule(module)

    if Array.isArray(path)
      path.forEach (entry, index) =>
        return if index == path.length - 1
        subPath = path[0..index]
        unless @__hasState(subPath)
          console.log(subPath, @__hasState(subPath))
          store.registerModule(subPath, {namespaced: true})
    unless @__hasState(path)
      store.registerModule(path, _module, options)

  __hasState: (paths) ->
    state = store.state
    paths.every (entry, index) ->
      state = state[entry]
      state?


  unregisterModule: (path) ->
    store.unregisterModule(path)

  addSupportedModule: (name, module) ->
    @__supportedModule ||= {}
    @__supportedModule[name] = module

  findSupportedModule: (name) ->
    @__supportedModule ||= {}
    @__supportedModule[name]

window.Agiki ||= {}
window.Agiki.Store = new Store()

export default store
