class VueI18n
  @install: (Vue, options) ->
    @__registerGlobal(Vue, options)
    @__registerInstance(Vue, options)

  @__registerGlobal: (Vue, options) ->
    Vue.$i18n = VueI18n.__i18nHandler
    Vue.$I18n = VueI18n.__i18nHandler

    Vue.$pageLang = VueI18n.__pageLangHandler

  @__registerInstance: (Vue, options) ->
    Vue.prototype.$i18n = VueI18n.__i18nHandler
    Vue.prototype.$I18n = VueI18n.__i18nHandler

    Vue.prototype.$pageLang = VueI18n.__pageLangHandler

  @__i18nHandler: (key = null, defaulValue = null) ->
    if key?
      keyPart = key.split('.')
      v = window.I18n

      keyPart.forEach (k) ->
        if v[k]?
          v = v[k]
        else
          v = ''

      v
    else
      window.I18n

  @__pageLangHandler: ->
    VueI18n.__pageLang ||= document.querySelector('html').lang


export default VueI18n
