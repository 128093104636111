import Vue from 'vue'
import store from '../store'

class VueComponentInitializer
  @event = 'turbolinks:load'

  @initStore: (options = {}) ->
    _store = options.store || store

    document.querySelectorAll("[data-vue-store]").forEach (element) =>
      return if element.dataset['done']
      payload = JSON.parse(element.dataset['vueStore'])
      moduleName = element.dataset['moduleName']
      modulePath = element.dataset['modulePath']
      if modulePath.startsWith('[')
        modulePath = JSON.parse(modulePath)

      if moduleName? && moduleName.trim() != ''
        Agiki.Store.registerModule(modulePath, moduleName)
        _modulePath = modulePath
        _modulePath = modulePath.join('/') if Array.isArray(modulePath)
        _store.commit("#{_modulePath}/initValues", payload)
      else
        _store.commit('initValues', payload)
      element.dataset['done'] = true

  # options:
  #   components: [{selector: '', component: VueObject}]
  #   store: Object
  #   scope: Element
  @initComponents: (options) ->
    return unless options.components?
    instances = new Array
    options.components.forEach (component) =>
      return unless component.selector?
      return unless component.component?

      scope = options.scope || document

      scope.querySelectorAll(component.selector).forEach (element) =>
        data = VueComponentInitializer.copyData(element.dataset)
        Object.keys(data).forEach (key) =>
          v = data[key]
          if (v == 'true' || v == 'false')
            data[key] = v == 'true'
          else if (v.startsWith('[') && v.endsWith(']'))
            data[key] = JSON.parse(v)
          else if (v.startsWith('{') && v.endsWith('}'))
            data[key] = JSON.parse(v)

        delete data['vue-component']
        delete data['vueComponent']

        if options['dataChanger']
          options['dataChanger'](data)

        componentOptions = {
          el: element
          data: data
        }

        if options.store?
          componentOptions.store = options.store
        else
          componentOptions.store = store

        if options.apolloProvider?
          componentOptions.apolloProvider = options.apolloProvider
        else if window.__apolloProvider?
          componentOptions.apolloProvider = window.__apolloProvider

        instance = new Vue(Object.assign(componentOptions, component.component))
        instances.push(instance)
    instances

  @copyData: (dataset) ->
    # NOTE: это метод костыль для <= IE10.
    #       так как <= IE10 не умеют dataset, то используется костыль для этого свойства (прописан в ie.js)
    #       и почему то в случае костыля для dataset не работает Object.assig, всегда возвращается {}
    #       чтобы это как-то обойти копирует свойство ручками и молимся что все нормально будет копироваться
    r = Object.assign({}, dataset)
    if Object.getOwnPropertyNames(r).length == 0
      Object.getOwnPropertyNames(dataset).forEach (name) =>
        r[name] = dataset[name]
    r

  @initAll: (options = {}) ->
    @initStore(options)
    @initComponents(options)

  @registerStore: (options)->
    document.addEventListener @event, =>
      @initStore(options)

  @registerComponents: (options)->
    document.addEventListener @event, =>
      @initComponents(options)

  @registerAll: (options) ->
    document.addEventListener @event, =>
      @initAll(options)

window.Agiki ||= {}
window.Agiki.Common ||= {}
window.Agiki.Common.VueComponentInitializer = VueComponentInitializer

export default VueComponentInitializer
