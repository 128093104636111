/*
 * Custom Vue directives
 */

module.exports = {
  install: function (Vue, options) {
    Vue.directive('click-outside', {
      // Вариант для v-show, для v-if нужно использовать bind и unbind
      componentUpdated: function(el, bindings, vnode){
        if ($(el).is(':visible')){
          var elemClick = function(e){
            e.stopPropagation()
          }
          var bodyClick = function(e){
            if(e.target.className.match(/^select2-/)){
              return
            }
            el.removeEventListener('click', elemClick)
            document.body.removeEventListener('click', bodyClick)
            bus.$emit('click-on-body', vnode.child._uid)
          }
          // setTimeout добавлен, чтобы в вебкитах прошел ивент клика по кнопке, иначе формы сразу же закрывались по клику извне
          setTimeout(
            function(){
              el.addEventListener('click', elemClick)
              document.body.addEventListener('click', bodyClick)
            },
            100
          )
        }
      },
      // Вариант для v-if
      bind: function(el, bindings, vnode){
        // Проверка наличия аргумента show, чтобы завязанные на апдейт директивы не срабатывали на биндинг
        // поскольку они биндятся один раз и ивент закрытия срабатывается всегда для последнего элемента
        if (bindings.arg != 'show'){
          this.bodyEvent = function(e){
            if(e.target.className.match(/^select2-/)){
              return
            }
            bus.$emit('click-on-body', vnode.child._uid)
          }
          this.formEvent = function(e){
            e.stopPropagation()
          }
          // setTimeout добавлен, чтобы в вебкитах прошел ивент клика по кнопке, иначе формы сразу же закрывались по клику извне
          setTimeout(
            function(){
              el.addEventListener('click', this.formEvent)
              document.body.addEventListener('click', this.bodyEvent)
            },
            100
          )
        }
      },
      unbind: function(el, bindings, vnode){
        el.removeEventListener('click', this.formEvent)
        document.body.removeEventListener('click', this.bodyEvent)
      },
    })
  }
};
