









DATA_MIXIN = {
  data: ->
    feedbackEmail: ''
    feedbackSubject: ''
    feedbackBody: ''
    linkText: ''
    pageUrl: window.location.href
}

export default {
  mixins: [DATA_MIXIN]

  computed:
    linkUrl: ->
      "mailTo:#{@feedbackEmail}?subject=#{@feedbackSubject} #{@pageUrl}&body=#{@feedbackBody}"

  methods:
    clickHandler: ->
      @setUrl()
      @openWindow()
    setUrl: ->
      @pageUrl = window.location.href
    openWindow: ->
      window.open(@linkUrl);
}
