import Vue from 'vue';

import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

meta = document.querySelector("meta[name='agiki-sentry-vue-dsn']")
if meta
  Sentry.init({
    dsn: meta.content,
    integrations: [new Integrations.Vue({Vue, attachProps: true, logError: true})],
  });
